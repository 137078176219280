import React, { Component } from 'react'
import Landing from '../../../../../components/case-studies/Landing'
import AboutProject from '../../../../../components/case-studies/AboutProject'
import PagesShowcase from '../../../../../components/case-studies/PagesShowcase'
import ProjectShortDescription from '../../../../../components/case-studies/ProjectShortDescription'
import ColorShowcase from '../../../../../components/case-studies/ColorShowcase'
import FontShowcase from '../../../../../components/case-studies/FontShowcase'
import Footer from '../../../../../components/case-studies/Footer'
import Img from 'gatsby-image'
import Layout from '../../../../../components/layout'
import { graphql } from 'gatsby'
import Reveal from '../../../../../components/reveal'
import ProjectImageLightBox from '../../../../../components/case-studies/ProjectImageLightBox'

class TatryGolfResidencePark extends Component {
  render() {
    const { data } = this.props
    const { banner_url, screens } = data.project.childMarkdownRemark.frontmatter

    return (
      <Layout showBackButton={true}>
        <Landing
          disableMaxHeight={true}
          title={'Firemná webstránka Tatry Golf Residence Park'}
          description={'Prípadová štúdia'}
          description2={'Profesionálna webová stránka pre lukratívne rezidencie v Tatrách so všetkým čo k modernému projektu patrí.'}
          text={'Pre Tatry Golf Residence Park sme vytvorili reprezentatívny web s premysleným vizuálom, návrh loga a tiež kompletný branding projektu. Projekt je preto ľahko zapamätateľný a má úspech.'}
          img={data.landingImage}
          what_we_done={[
            { text: 'logo & branding', link: '/nase-prace/grafika/logo-branding/tatry-golf-residence-park-branding' },
            { text: 'ux & web design' },
            { text: 'programovanie (wordpress)' },
          ]} />

        <AboutProject
          title={'Moderná webstránka na mieru nevznikne sama'}
          text={'Toto všetko obnáša tvorba webstránok, ktoré klientom úspešne zarábajú.'}
          list={[
            {
              title: 'Uchopenie projektu',
              text: 'Klient nás oslovil s tým, že potreboval pre svoj projekt profesionálnu <a class="blue-link" href="/tvorba-webov">webovú stránku</a>, ktorá vystihne jeho zámer a pritiahne tú správnu cieľovú skupinu záujemcov o tento typ nehnuteľností. Najskôr sme si s klientom prešli všetky požiadavky, aby sme <b>pochopili jeho očakávania</b> a spracovali projektovú dokumentáciu, ktorá by ich reálne naplnila.',
            },
            {
              title: 'Návrh riešenia',
              text: 'Úloha bola jasná. Vytvoriť moderný web pre záujemcov, ktorí rýchlo nájdu všetky informácie vrátane dostupnosti pozemkov. To všetko v špičkovom <a class="blue-link" href="/webdizajn">webdizajne</a> s novým logom a brandingom, a zároveň na najlepšej technologickej úrovni <b>funkcií, rýchlosti a SEO</b>. V rámci úzkej spolupráce sme s klientom diskutovali, ako tieto plány zrealizovať.',
            },
            {
              title: 'Priebeh vývoja',
              text: 'Dizajnér mal voľnú ruku, popustil uzdu kreativite a vytvoril originálny štýl/moodboard. Ďalej sa navrhli varianty loga a celá <a class="blue-link" href="/branding">korporátna identita</a>. Po schválení sme sa pustili do wireframov (rozloženia stránok) a finálneho webdizajnu. Pravidelne sme klientovi prezentovali výstupy <b>a diskutovali o nich</b>. Projekt sme finálne zverili do rúk našich programátorov.',
            },
            {
              title: 'Úspešný výsledok',
              text: 'Pred samotným spustením web optimalizujeme pre prehliadače, responzívnosť a SEO, pre čo najlepšie pozície na Googli. Odovzdaním webu spolupráca s klientom nekončí a projekt vylepšujeme ďalej. Sledujeme správanie návštevníkov cez Hotjar a Google Analytics a na základe získaných dát klientom radíme <b>ako zvýšiť ich tržby</b>.',
            }
          ]}
        />

        <ProjectShortDescription
          title={'Tvorba brandu'}
          description={'Predpokladom plnohodnotnej firemnej identity je pochopenie projektu a jeho zámeru. Návrh loga prichádza potom.'}
          text={'Po konzultáciách s klientom sme pripravili zrozumiteľné firemné princípy, ktoré by mal projekt prezentovať. Následne sme odkomunikovali základné piliere toho, ako sa <b>odlíšiť od konkurencie</b> a cez nový branding značky ukázať jedinečnú esenciu firmy.'} />

        <Reveal>
          <div className={'container p-0 mb-4'}>
            <Img alt={this.constructor.name} fluid={data.tatryLogos.childImageSharp.fluid} />
          </div>
        </Reveal>

        <ColorShowcase colors={[
          { code: '#067eed', tintColorCode: '#3898f1', nameColor: '#FFFFFF', name: 'Azúrová modrá' },
          { code: '#70b128', tintColorCode: '#8dc153', nameColor: '#FFFFFF', name: 'Trávová zelená' },
          { code: '#343f49', tintColorCode: '#585f66', nameColor: '#FFFFFF', name: 'Antracitová' },
          { code: '#ffffff', tintColorCode: '#ffffff', nameColor: '#6B7B8A', name: 'Biela' },
        ]} />

        <FontShowcase
          fontName={'Asap'}
          text={'Asap je súčasná moderná rodina bezpätkových písiem s jemne zaoblenými rohmi. Nadizajnoval ju Pablo Cosgaya. Význam Asap je “as soon as possible”. Existujú 4 štýly: Klasický, kurzíva, hrubý a hrubá kurzíva. Táto rodina písiem, ktorá bola navrhnutá špeciálne na použitie na webe, poskytuje štandardizovanú šírku znakov vo všetkých štýloch, čo zaručuje, že riadky majú rovnakú dĺžku. Táto užitočná funkcia dovoľuje užívateľom meniť štýly písma bez toho, aby museli zalamovať text nanovo. Asap vychádza z písma Ancha (navrhnutého Pablom Cosgayom a Hectorom Gattim) a bolo vytvorené v spolupráci s Andrésom Torresim.'}
          isFullWidth={false}
          fontExampleSvg={'/images/svg/fonts/asap.svg'}
        />

        <Reveal>
          <div className={'container p-0'}>
            <Img alt={this.constructor.name} fluid={data.tatryCorporateIdentity.childImageSharp.fluid} />
          </div>
        </Reveal>

        <PagesShowcase
          fixedTitleWidth={true}
          title={'Webový dizajn na mieru'}
          description={'Profesionálna webstránka sa bez neho nezaobíde.'}
          text={'Prepracované UX, high-fidelity wireframy a webový dizajn na mieru spĺňajú všetko, čo by mal moderný web obsahovať. Pridanou hodnotou sú ilustrácie a vizualizácie vytvárané na mieru. Perfektná responzívnosť a mobile first prístup sú už len čerešničkou na torte.'}
          projectDetail={'/nase-prace/webstranky/firemne/tatry-golf-residence-park'}
          pages={[
            {
              name: 'Hlavná stránka',
              image: data.pageOne,
            },
            {
              name: 'Ponuka pozemkov',
              image: data.pageTwo,
            },
            {
              name: 'Galéria',
              image: data.pageThree,
            },
            {
              name: 'O nás',
              image: data.pageFour,
            },
            {
              name: 'Lokalita',
              image: data.pageFive,
            },
            {
              name: 'Kontakt',
              image: data.pageSix,
            },
          ]} />

        {/* All images */}
        <Reveal>
          <div className='container mt-5 mb-5 pt-5 pb-5 casestudy-lightbox'>
            <ProjectImageLightBox bannerUrl={banner_url} screens={screens} title="letenky" />
          </div>
        </Reveal>

        {/*
        <ClientReference
          text={'Nara.design sme kontaktovali na redizajn nášho portálu hypoteka.sk ktorý už bol niekoľko rokov zastaralý. Po niekoľkých stretnutiach prišli dizajnéry s desiatkami vylepšení a skrášlení, ktoré sme ako jeden z najnavštevovanejších portálov potrebovali.'}
          clientName={'David Kopecký'}
          clientRole={'Majiteľ Allriskslovakia'}
          clientPhoto={data.clientPhoto}
        />
*/}

        <div className="extern-page-link">
          <a rel="noreferrer" href="https://www.tgrp.sk" target='_blank' className='link-secondary'>www.tgrp.sk</a>
        </div>

        <Footer
          title={'OurServices.Process'}
          subtitle={'OurServices.ProcessSubTitle'}
          link={'/o-nas/ako-pracujeme'}
          leftTopTitle='project'
          nextProjectName={'Trustpay'}
          nextProjectUrl={'/nase-prace/pripadove-studie/webstranky/firemne/trustpay/'}
        />
      </Layout>
    )
  }
}

export const query = graphql`{

    project:  file(relativePath: {eq: "posts/case-studies/tatry_golf_residence_park.md"}) {
        childMarkdownRemark {
          frontmatter {
            banner_url
              screens {
                  href {
                      childImageSharp {
                          fluid(quality: 100, maxWidth: 1920) {
                              ...GatsbyImageSharpFluid_withWebp
                              presentationWidth
                          }
                      }
                  }
                  alt
              }
          }
        }
    }
    landingImage: file(relativePath: { eq: "case-studies/1_tatryweb.jpg" }) {
        childImageSharp {
            fluid( maxWidth: 1600) {
                ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
    clientPhoto: file(relativePath: { eq: "testimonial_person.png" }) {
        childImageSharp {
            fixed( width: 250) { 
                ...GatsbyImageSharpFixed_withWebp
            }
        }
    }
    pageOne: file(relativePath: { eq: "case-studies/1_tatryweb.jpg" }) {
        childImageSharp {
            fluid( maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
    pageTwo: file(relativePath: { eq: "case-studies/2_tatryweb.jpg" }) {
        childImageSharp {
            fluid( maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
    pageThree: file(relativePath: { eq: "case-studies/3_tatryweb.jpg" }) {
        childImageSharp {
            fluid( maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
    pageFour: file(relativePath: { eq: "case-studies/4_tatryweb.jpg" }) {
        childImageSharp {
            fluid( maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
    pageFive: file(relativePath: { eq: "case-studies/5_tatryweb.jpg" }) {
        childImageSharp {
            fluid( maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
    pageSix: file(relativePath: { eq: "case-studies/6_tatryweb.jpg" }) {
        childImageSharp {
            fluid( maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
    tatryLogos: file(relativePath: { eq: "case-studies/TatryGolfResidence_logos.png" }) {
        childImageSharp {
            fluid( maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
    tatryCorporateIdentity: file(relativePath: { eq: "case-studies/TatryGolfResidence_CorporateIdentity.png" }) {
        childImageSharp {
            fluid( maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
}
`

export default TatryGolfResidencePark
